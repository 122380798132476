@charset "UTF-8";
/* VARIAVEIS

// 1.0 - font-family and font-size
// 2.0 - Colors
// 3.0 - borders
// 4.0 -
// 5.0 -

*/
* {
  margin: 0px;
  padding: 0px; }

body {
  background: #F4F4F4;
  font-family: "Open Sans"; }

ul {
  list-style: none; }

a {
  text-decoration: none; }

.img-responsive {
  width: 100%; }

footer {
  background: #C099FF;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-top: 100px;
  text-align: center; }
  footer i, footer span {
    color: #8AC1E7; }

.navbar-nv1 {
  padding-top: 15px;
  padding-bottom: 10px;
  background: #f2f2f2; }
  .navbar-nv1 figure {
    margin: 0px; }
  .navbar-nv1 .logo-topo img {
    max-width: 170px; }
  .navbar-nv1 .info-header {
    padding-top: 4px;
    display: inline-flex;
    float: right; }
    .navbar-nv1 .info-header .notifications-header .btn-notificacao {
      background: none;
      margin-right: 20px;
      padding: 0px; }
      .navbar-nv1 .info-header .notifications-header .btn-notificacao i {
        font-size: 24px;
        color: #888585;
        opacity: 1;
        transition: all .3s linear; }
      .navbar-nv1 .info-header .notifications-header .btn-notificacao .badge {
        position: relative;
        top: -15px;
        width: 20px;
        height: 20px;
        background: #F44439;
        color: white;
        line-height: 13px;
        border-radius: 20px; }
      .navbar-nv1 .info-header .notifications-header .btn-notificacao:focus {
        outline: none !important;
        box-shadow: none !important; }
        .navbar-nv1 .info-header .notifications-header .btn-notificacao:focus i {
          color: #7229e6;
          transition: all .2s linear;
          transform: rotate(20deg); }
    .navbar-nv1 .info-header .notifications-header .notificacoes-popover {
      width: 400px;
      position: absolute;
      background: #fff;
      z-index: 999999;
      top: 50px;
      transform: translateX(-360px);
      border: 1px solid #f2f2f2;
      border-radius: 6px;
      box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
      -webkit-box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.1); }
      .navbar-nv1 .info-header .notifications-header .notificacoes-popover .header-popover {
        padding-left: 20px;
        text-align: left;
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 15px;
        text-transform: uppercase;
        font-weight: 700;
        position: fixed;
        width: 100%;
        background: #f2f2f2;
        display: flex;
        justify-content: space-between;
        align-items: center; }
        .navbar-nv1 .info-header .notifications-header .notificacoes-popover .header-popover p {
          margin: 0px; }
        .navbar-nv1 .info-header .notifications-header .notificacoes-popover .header-popover a .btn-popover {
          font-size: 13px;
          padding-left: 20px !important;
          padding-right: 20px !important;
          padding-top: 10px !important;
          padding-bottom: 10px !important;
          border-radius: 6px;
          color: white;
          font-weight: 600;
          background: #00BCD4 !important;
          margin-right: 20px !important;
          border: none;
          transition: all .3s linear; }
          .navbar-nv1 .info-header .notifications-header .notificacoes-popover .header-popover a .btn-popover:hover {
            cursor: pointer;
            background: white !important;
            transition: all .3s linear;
            color: #C099FF; }
      .navbar-nv1 .info-header .notifications-header .notificacoes-popover ul {
        max-height: 300px;
        overflow: auto;
        margin-top: 70px; }
        .navbar-nv1 .info-header .notifications-header .notificacoes-popover ul li {
          padding: 20px;
          text-align: justify;
          color: #7229e6;
          font-weight: 600;
          padding-bottom: 10px;
          padding-top: 10px;
          border-bottom: 1px solid #f2f2f2;
          font-size: 15px; }
          .navbar-nv1 .info-header .notifications-header .notificacoes-popover ul li span {
            color: #888585;
            font-weight: 400;
            font-size: 13px; }
          .navbar-nv1 .info-header .notifications-header .notificacoes-popover ul li:hover {
            background: #f2f2f2;
            cursor: pointer; }
      .navbar-nv1 .info-header .notifications-header .notificacoes-popover.hidden {
        opacity: 0;
        transition: all .2s linear;
        position: absolute;
        top: 100px;
        overflow: hidden;
        z-index: -1; }
      .navbar-nv1 .info-header .notifications-header .notificacoes-popover.show {
        margin-top: 0px;
        opacity: 10;
        transition: all .2s linear;
        top: 50px;
        max-height: 500px;
        z-index: 999; }
    .navbar-nv1 .info-header .profile-header .label-user {
      font-size: 13px;
      text-transform: capitalize; }
    .navbar-nv1 .info-header .profile-header .btn-profile {
      background: transparent;
      border: none;
      display: flex;
      display: inline-flex;
      align-items: center;
      transition: all .2s linear; }
      .navbar-nv1 .info-header .profile-header .btn-profile i {
        font-size: 26px;
        color: #888585; }
      .navbar-nv1 .info-header .profile-header .btn-profile figure {
        margin: 0px; }
      .navbar-nv1 .info-header .profile-header .btn-profile:focus {
        transition: all .2s linear;
        outline: none;
        border: none; }
        .navbar-nv1 .info-header .profile-header .btn-profile:focus i {
          color: #7229e6;
          transition: all .2s linear; }
        .navbar-nv1 .info-header .profile-header .btn-profile:focus .label-user {
          color: #7229e6;
          font-weight: 600;
          transition: all .2s linear; }
      .navbar-nv1 .info-header .profile-header .btn-profile:hover {
        cursor: pointer; }
    .navbar-nv1 .info-header .profile-header .profile-popover {
      position: absolute;
      min-width: 250px;
      top: 50px;
      transform: translateX(-100px);
      background: #fff;
      border-radius: 6px;
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
      -webkit-box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
      -moz-box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
      z-index: 999; }
      .navbar-nv1 .info-header .profile-header .profile-popover ul {
        margin-top: 10px;
        margin-bottom: 10px;
        width: 100%; }
        .navbar-nv1 .info-header .profile-header .profile-popover ul a, .navbar-nv1 .info-header .profile-header .profile-popover ul .btn-visualizar-como, .navbar-nv1 .info-header .profile-header .profile-popover ul .btn-logout {
          font-size: 14px;
          color: #888585;
          font-weight: 400;
          width: 100%;
          margin: 0px;
          text-align: left;
          border: none;
          background: transparent; }
          .navbar-nv1 .info-header .profile-header .profile-popover ul a:hover, .navbar-nv1 .info-header .profile-header .profile-popover ul .btn-visualizar-como:hover, .navbar-nv1 .info-header .profile-header .profile-popover ul .btn-logout:hover {
            cursor: pointer; }
          .navbar-nv1 .info-header .profile-header .profile-popover ul a li, .navbar-nv1 .info-header .profile-header .profile-popover ul .btn-visualizar-como li, .navbar-nv1 .info-header .profile-header .profile-popover ul .btn-logout li {
            padding-left: 1rem;
            height: 50px;
            width: 100%;
            padding-top: 13px; }
            .navbar-nv1 .info-header .profile-header .profile-popover ul a li:hover, .navbar-nv1 .info-header .profile-header .profile-popover ul .btn-visualizar-como li:hover, .navbar-nv1 .info-header .profile-header .profile-popover ul .btn-logout li:hover {
              background: #D1E8F4;
              text-decoration: none;
              font-weight: 500; }
          .navbar-nv1 .info-header .profile-header .profile-popover ul a:hover, .navbar-nv1 .info-header .profile-header .profile-popover ul .btn-visualizar-como:hover, .navbar-nv1 .info-header .profile-header .profile-popover ul .btn-logout:hover {
            text-decoration: none; }
        .navbar-nv1 .info-header .profile-header .profile-popover ul .btn-visualizar-como.hidden {
          display: none; }
        .navbar-nv1 .info-header .profile-header .profile-popover ul .btn-visualizar-como.show {
          display: block; }
        .navbar-nv1 .info-header .profile-header .profile-popover ul .acoes-visualizar-como.hidden {
          display: none;
          margin-bottom: 10px; }
        .navbar-nv1 .info-header .profile-header .profile-popover ul .acoes-visualizar-como.show {
          display: flex; }
          .navbar-nv1 .info-header .profile-header .profile-popover ul .acoes-visualizar-como.show .label-back {
            font-size: 14px; }
          .navbar-nv1 .info-header .profile-header .profile-popover ul .acoes-visualizar-como.show .btn-back {
            margin-bottom: 10px;
            width: 100%;
            border-radius: 0px !important;
            text-align: left;
            background: #f2f2f2;
            color: #888585; }
          .navbar-nv1 .info-header .profile-header .profile-popover ul .acoes-visualizar-como.show .visualizar-como-label {
            width: 100%;
            font-size: 13px;
            margin-left: 16px;
            color: #888585; }
          .navbar-nv1 .info-header .profile-header .profile-popover ul .acoes-visualizar-como.show .visualizar-como-input {
            margin-top: 7px;
            margin-left: 16px;
            border-top: none;
            border-left: none;
            border-right: none;
            border-radius: 0px;
            font-size: 12px;
            padding-left: 0px;
            color: #888585;
            max-width: 150px; }
            .navbar-nv1 .info-header .profile-header .profile-popover ul .acoes-visualizar-como.show .visualizar-como-input:focus {
              outline: none !important;
              box-shadow: none; }
          .navbar-nv1 .info-header .profile-header .profile-popover ul .acoes-visualizar-como.show .visualizar-como-buscar {
            background: #C099FF !important; }
        .navbar-nv1 .info-header .profile-header .profile-popover ul .lista-visualizar-como {
          margin-left: 0px;
          margin-right: 0px;
          margin-top: 20px;
          padding-bottom: 10px;
          margin-bottom: 0px;
          padding-left: 16px;
          padding-right: 16px;
          max-height: 200px;
          overflow: auto;
          box-shadow: inset 0 0 25px #e2e2e2; }
          .navbar-nv1 .info-header .profile-header .profile-popover ul .lista-visualizar-como li {
            border-bottom: 1px solid #ccc; }
            .navbar-nv1 .info-header .profile-header .profile-popover ul .lista-visualizar-como li a {
              justify-content: space-around;
              line-height: 40px;
              font-size: 11px;
              height: 40px;
              color: #000;
              transition: all .3s ease; }
              .navbar-nv1 .info-header .profile-header .profile-popover ul .lista-visualizar-como li a:hover {
                font-weight: 600;
                color: #7229e6 !important;
                cursor: pointer;
                transition: all .3s ease; }
      .navbar-nv1 .info-header .profile-header .profile-popover.hidden {
        opacity: 0;
        transition: all .2s ease-in-out;
        position: absolute;
        top: 80px;
        z-index: -1;
        overflow: auto; }
      .navbar-nv1 .info-header .profile-header .profile-popover.show {
        margin-top: 0px;
        opacity: 10;
        transition: all .2s ease-in-out;
        top: 50px;
        max-height: 500px;
        z-index: 999; }

.navbar-nv2 {
  background: #7229e6;
  border-bottom: 1px solid #C099FF !important; }
  .navbar-nv2 ul {
    display: inline-flex;
    margin: 0px; }
    .navbar-nv2 ul a li {
      font-size: 14px;
      border-right: 1px solid rgba(248, 248, 248, 0.11);
      color: #f8f8f8;
      padding: 15px 30px;
      letter-spacing: 1.5px;
      text-transform: uppercase; }
    .navbar-nv2 ul a:hover {
      background: #C099FF;
      text-decoration: none; }

.navbar-nv2 {
  background: #7229e6;
  border-bottom: 10px solid #C099FF; }
  .navbar-nv2 ul {
    display: inline-flex;
    margin: 0px; }
    .navbar-nv2 ul a li {
      border-right: 1px solid rgba(248, 248, 248, 0.11);
      color: #f8f8f8;
      padding: 15px 30px;
      letter-spacing: 1.5px;
      text-transform: uppercase; }
      .navbar-nv2 ul a li:hover {
        background: #C099FF;
        text-decoration: none; }
    .navbar-nv2 ul .menu-dropdown {
      border-right: 1px solid rgba(248, 248, 248, 0.11) !important;
      color: #f8f8f8;
      padding: 15px 30px;
      letter-spacing: 1.5px;
      text-transform: uppercase;
      background: #7229e6;
      border: none;
      position: relative; }
      .navbar-nv2 ul .menu-dropdown:hover {
        background: #C099FF;
        text-decoration: none;
        cursor: pointer; }
      .navbar-nv2 ul .menu-dropdown:focus {
        outline: none !important;
        background: #C099FF; }
      .navbar-nv2 ul .menu-dropdown ul.hidden {
        display: none !important; }
      .navbar-nv2 ul .menu-dropdown ul.show {
        display: block !important;
        position: absolute;
        background: #fff;
        margin-top: 35px;
        width: 360px;
        transform: translateX(-33px);
        box-shadow: 0 10px 25px #DEDEDE;
        z-index: 9999; }
        .navbar-nv2 ul .menu-dropdown ul.show a li {
          text-align: left;
          color: #919191;
          text-transform: uppercase;
          font-weight: 600;
          font-size: 15px;
          letter-spacing: 0px;
          padding-top: 15px;
          padding-bottom: 15px; }
          .navbar-nv2 ul .menu-dropdown ul.show a li:hover {
            background: #DEEEF6;
            color: #C099FF; }

.btn-nav-mobile {
  display: none;
  margin-left: 20px; }

.navbar-nv2-mobile.hidden {
  max-height: 0;
  transition: max-height .1s ease-out;
  overflow: hidden; }

.navbar-nv2-mobile.show {
  max-height: 1000px;
  transition: max-height .2s ease-in;
  overflow: hidden; }

.btn-navbar {
  margin-left: 20px;
  margin-top: -10px;
  width: 35px;
  height: 35px;
  border-radius: 30px;
  transition: all .3s ease; }
  .btn-navbar:focus {
    outline: none;
    background: #8AC1E7;
    border: 1px solid #8AC1E7;
    box-shadow: 0 0 15px #ccc; }
  .btn-navbar:hover {
    cursor: pointer;
    background: #8AC1E7;
    transition: all .3s ease;
    border: 1px solid #8AC1E7; }

.navbar-nv2-mobile {
  background: #7229e6;
  border-bottom: 5px solid #C099FF; }
  .navbar-nv2-mobile ul {
    margin: 0px; }
    .navbar-nv2-mobile ul a li {
      color: #f8f8f8;
      padding: 15px 30px;
      letter-spacing: 1.5px;
      text-transform: uppercase;
      text-align: center; }
    .navbar-nv2-mobile ul a:hover {
      text-decoration: none; }
      .navbar-nv2-mobile ul a:hover li {
        background: #60B2DD; }

@media (max-width: 790px) {
  .hidden-xs {
    display: none !important; }
  .navbar-nv1 {
    padding-top: 10px;
    padding-bottom: 10px; }
    .navbar-nv1 .logo-topo {
      padding-top: 10px; }
  .notifications-header {
    padding-top: 10px; }
    .notifications-header .btn-notificacao {
      margin-right: 0px !important; }
    .notifications-header .notificacoes-popover {
      position: absolute;
      transform: translateX(-200px) !important;
      max-width: 350px; }
      .notifications-header .notificacoes-popover:after, .notifications-header .notificacoes-popover:before {
        right: 19% !important; }
  .profile-header {
    padding-top: 10px; }
    .profile-header .profile-popover {
      transform: translateX(-150px) !important;
      display: flex; }
      .profile-header .profile-popover:after, .profile-header .profile-popover:before {
        right: 8% !important; }
    .profile-header .hidden-xs {
      margin-top: 10px;
      margin-left: 10px; }
  .btn-navbar {
    margin-top: 5px !important; } }

@media (max-width: 990px) {
  .hidden-sm {
    display: none; }
  .btn-nav-mobile {
    display: block; }
  .menu-mobile {
    display: block; } }

@media (min-width: 992px) {
  .hidden-md {
    display: none; } }

.classification-user-statistics p {
  text-align: left;
  margin: 0px; }

.rt-tfoot .rt-td {
  background: #7229e6 !important;
  color: #fff !important;
  padding: 0px 0px 0px 0px;
  letter-spacing: 0.5px; }
  .rt-tfoot .rt-td p {
    padding: 10px 0px 0px 0px; }
  .rt-tfoot .rt-td table {
    margin: 0;
    padding: 0;
    max-width: 100%;
    display: block;
    font-weight: 600 !important; }
    .rt-tfoot .rt-td table tbody {
      max-width: 100%;
      display: block; }
    .rt-tfoot .rt-td table td {
      max-width: 100%;
      display: block; }
    .rt-tfoot .rt-td table tr {
      padding: 10px 0px 10px 0px;
      display: block; }
      .rt-tfoot .rt-td table tr:nth-child(2) {
        background: #033F7B;
        border-top: 2px solid #fff; }

/*
CONTEÚDO

0. GERAL
1. BACKGROUNDS
2. TEXTS
3. BUTTONS
4. SECTIONS
5. FORMS
6. LOADER

*/
.btn-speed-fast {
  position: fixed;
  left: 5px;
  top: 10px;
  background: #7229e6 !important;
  line-height: 5px !important;
  min-width: 30px;
  height: 30px; }

.btn-speed-slow {
  position: fixed;
  left: 5px;
  top: 50px;
  background: #DD7C60 !important;
  line-height: 5px !important;
  width: 36px;
  height: 30px; }

.pull-right {
  float: right; }

body {
  font-family: "Open Sans" !important;
  color: #4A4747;
  overflow-x: hidden; }

.top-0 {
  top: 0; }

.info-pagination {
  background: #f1f1f1;
  border: 1px solid #ccc;
  text-transform: uppercase;
  font-size: 14px; }

.img-provas-thumb:HOVER {
  cursor: pointer;
  box-shadow: 0 0 15px #ccc; }

.no-select-img-text {
  color: #888585;
  font-weight: 700;
  padding-top: 20px;
  padding-right: 20px; }

/* ------ 1. BACKGROUNDS -------- */
.page-container-border {
  padding: 20px;
  padding-bottom: 3rem;
  background: #ffffff;
  border: 20px solid #f8f8f8;
  border-radius: 6px;
  margin-top: 3rem;
  height: auto !important; }
  @media screen and (max-width: 979px) {
    .page-container-border {
      border: 10px solid #f8f8f8; } }

.default-background {
  background: #f8f8f8; }

@media screen and (min-width: 1280px) {
  .container {
    padding: 10px 40px; } }

@media screen and (max-width: 979px) {
  .container {
    height: 100% !important;
    padding-left: 15px !important;
    padding-right: 15px !important; } }

/* ------ 2. TEXTS -------- */
.danger {
  color: #D38155 !important; }

.success {
  color: #659C56 !important; }

.text-blue {
  color: #00ACAC !important; }

.gray {
  color: #888585 !important; }

.default {
  color: #7229e6 !important; }

.text-title {
  color: #C099FF;
  font-size: 25px !important;
  padding: 30px 0px 20px 0px !important;
  border-bottom: 1px solid #f2f2f2;
  font-weight: 400 !important; }

.text-subtitle {
  color: #60B2DD;
  font-size: 17px !important;
  text-transform: uppercase;
  letter-spacing: 0.2px; }

.text-information {
  padding-bottom: 10px;
  color: #9FA1A2; }
  .text-information span .text-bold {
    font-weight: 500; }
  .text-information span:not(.text-bold) {
    margin-right: 20px; }

/* ------ 3. BUTTONS -------- */
.btn {
  letter-spacing: 1px !important; }

.btn-disabled {
  color: #B1B2B4;
  font-size: 12px !important;
  font-weight: 600 !important;
  border: 1px solid #f2f2f2 !important;
  text-transform: uppercase;
  padding: 10px !important;
  opacity: 1 !important;
  margin-right: 20px;
  cursor: default;
  background: #fff; }

.btn-primary {
  font-size: 11px;
  border: none;
  padding: 0px 25px;
  min-height: 40px;
  background: #7229e6; }
  .btn-primary:hover {
    background: #7229e6;
    opacity: .7;
    transition: all .3s ease; }

.btn-terciary {
  font-size: 11px !important;
  border: none !important;
  padding: 0px 25px !important;
  min-height: 40px;
  background: #C099FF !important;
  color: #fff; }
  .btn-terciary:hover {
    background: #C099FF !important;
    opacity: .7;
    transition: all .3s ease; }

.btn-secondary {
  font-size: 11px !important;
  border: none !important;
  padding: 0px 25px !important;
  min-height: 40px;
  background: #DD7C60 !important; }
  .btn-secondary:hover {
    background: #E59E00 !important; }

.bg-primary-color {
  background-color: #7229e6 !important; }

.bg-warning-color {
  background-color: #E59E00 !important; }

.bg-danger-color {
  background-color: #DB6969 !important; }

.bg-default-color {
  background: #7229e6 !important;
  width: 100%;
  font-size: 14px !important;
  min-height: 50px;
  color: white;
  font-weight: 600 !important;
  border: none !important; }

.bg-default-color:HOVER {
  background: #8AC1E7 !important;
  width: 100%;
  font-size: 14px !important;
  color: white;
  font-weight: 600 !important; }

.bg-default-color-xs {
  background: #7229e6 !important;
  min-width: 100px;
  min-height: 50px;
  font-size: 14px !important;
  color: white;
  font-weight: 600 !important;
  border: none !important; }

.bg-default-color-xs:HOVER {
  background: #C099FF !important;
  font-size: 14px !important;
  color: white;
  font-weight: 600 !important;
  border: none !important; }

.bg-dark-default-color {
  background: #C099FF !important;
  width: 100%;
  font-size: 14px !important;
  min-height: 50px;
  color: white;
  font-weight: 600 !important;
  border: none !important; }

.bg-dark-default-color:hover {
  background: #60B2DD !important; }

.bg-warning-color-wh {
  background: #DD7C60 !important;
  width: 100%;
  min-height: 50px;
  font-size: 14px !important;
  color: white;
  font-weight: 600 !important; }

.bg-warning-color-wh:HOVER {
  background: #E59E00 !important;
  min-width: 100%;
  font-size: 14px !important;
  color: white;
  font-weight: 600 !important; }

.bg-warning-color {
  background: #E59E00 !important;
  min-width: 100%;
  font-size: 14px !important;
  color: white;
  min-height: 50px;
  font-weight: 600 !important; }

.bg-warning-color:hover {
  background: #DD7C60 !important;
  min-width: 100%;
  font-size: 14px !important;
  color: white;
  min-height: 50px;
  font-weight: 600 !important; }

.bg-dark-warning-color {
  background: #DD7C60 !important;
  font-size: 14px !important;
  color: white;
  min-height: 50px;
  font-weight: 600 !important; }

/* The container */
.input-group-custom {
  display: flex;
  justify-content: right;
  margin: 10px; }

.container-radio {
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
  display: block; }

/* Hide the browser's default checkbox */
.container-radio input {
  position: absolute;
  margin-top: -25px;
  opacity: 0;
  width: 30px;
  height: 30px;
  cursor: pointer; }

/* Create a custom checkbox */
.checkmark {
  font-weight: bold;
  color: #666;
  border: 1px solid #ccc;
  text-align: center;
  padding: 6px 10px;
  margin-right: 4px;
  border-radius: 6px; }

/* On mouse-over, add a grey background color */
.container-radio:hover input ~ .checkmark {
  background-color: #ccc;
  cursor: pointer; }

/* When the checkbox is checked, add a blue background */
.container-radio input:checked ~ .checkmark {
  background-color: #7229e6;
  color: white; }

.loading-style .loading {
  max-width: 50px;
  left: 40%;
  position: static;
  margin-top: 15%;
  margin-bottom: 15%; }

.-loading-inner .loading-style .loading {
  margin: 0 !important;
  position: relative !important;
  left: 0;
  top: -50px; }

.error-message {
  margin-top: 20px;
  font-size: 600;
  color: #a5a5a5;
  letter-spacing: 0.5px; }

.rodal-dialog {
  margin-top: 8rem !important;
  z-index: 9999999; }

.responsabilidade-modal {
  height: 100%; }
  .responsabilidade-modal .rodal-dialog {
    width: 50% !important;
    align-self: center !important;
    top: -100px !important;
    bottom: unset !important;
    height: auto !important;
    margin-top: 10% !important;
    padding: 8px !important; }
    .responsabilidade-modal .rodal-dialog .correcao {
      margin: 0px; }

.ReactTable .pagination-bottom {
  color: #9FA1A2; }
  .ReactTable .pagination-bottom .-pagination .-pageSizeOptions {
    position: absolute;
    left: 0;
    margin-top: 0; }
    .ReactTable .pagination-bottom .-pagination .-pageSizeOptions select {
      height: 39px;
      vertical-align: middle;
      color: #C099FF;
      font-weight: 600; }
  .ReactTable .pagination-bottom .-pagination button {
    max-width: 50px;
    margin: 0 !important;
    color: #fff !important;
    background: #7229e6; }
    .ReactTable .pagination-bottom .-pagination button:disabled {
      display: none; }
    .ReactTable .pagination-bottom .-pagination button:hover {
      background: #C099FF !important; }
  .ReactTable .pagination-bottom .-pagination .-previous {
    min-width: 50px;
    position: absolute;
    right: 68px;
    min-height: 39px;
    max-width: 50px; }
    .ReactTable .pagination-bottom .-pagination .-previous button {
      min-height: 42px; }
      .ReactTable .pagination-bottom .-pagination .-previous button:before {
        font-family: "Font Awesome 5 Free" !important;
        content: '\f359' !important; }
  .ReactTable .pagination-bottom .-pagination .-next {
    max-width: 50px; }
    .ReactTable .pagination-bottom .-pagination .-next button:before {
      font-family: "Font Awesome 5 Free" !important;
      content: '\f35a' !important; }
  .ReactTable .pagination-bottom .-pagination .-pageJump input {
    color: #7229e6; }

@media (max-width: 769px) {
  .ReactTable .-pagination button {
    display: block;
    float: none;
    margin: auto; }
  .ReactTable .-pagination .-previous {
    position: initial !important; }
  .ReactTable .-pagination .-next button {
    min-height: 42px; }
  .ReactTable .-pagination .-pageSizeOptions {
    position: initial !important; } }

@media (max-width: 490px) {
  .ReactTable .-center {
    margin-top: 10px !important;
    margin-bottom: 10px !important; } }

.toolbar-unordered-list {
  margin-bottom: 10px;
  background: #f1f1f1;
  border: 1px solid #ccc;
  padding: 1rem;
  display: grid;
  grid-gap: 2rem; }

.toolbar-container {
  display: flex;
  align-items: center; }
  .toolbar-container li {
    margin-right: 1rem; }

.loading {
  position: absolute;
  top: 50%;
  left: 50%; }

.login-bg {
  background: #fff;
  height: 100vh; }
  .login-bg .box-form {
    margin-top: 12%;
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 30px;
    border-bottom: 1px solid #7229e6; }
  .login-bg .logo-login {
    max-width: 180px;
    text-align: center;
    margin: auto;
    float: none;
    display: block; }
  .login-bg .alert {
    margin-top: 2%;
    font-size: 12px;
    text-align: left; }
    .login-bg .alert .close {
      padding: .50rem 1rem; }
  .login-bg .form-sign {
    margin-top: 10%; }
    .login-bg .form-sign .group-input {
      position: relative; }
      .login-bg .form-sign .group-input i {
        position: absolute;
        left: 20px;
        font-size: 15px;
        line-height: 95px;
        color: #585858; }
      .login-bg .form-sign .group-input input {
        padding-left: 40px;
        height: 50px;
        font-size: 16px;
        border-radius: 10px;
        color: #898A8B;
        font-weight: 600;
        border-width: 2px;
        background: transparent; }
        .login-bg .form-sign .group-input input::placeholder {
          font-size: 12px; }
        .login-bg .form-sign .group-input input:focus {
          border-color: #DB6969;
          border-color: #9AC75C;
          border-color: #E59E00;
          border-color: #7229e6;
          border-width: 2px;
          box-shadow: none;
          outline: 0 none; }
        .login-bg .form-sign .group-input input:focus.has-error {
          border-color: #DB6969;
          border-width: 2px;
          box-shadow: none;
          outline: 0 none; }
      .login-bg .form-sign .group-input input.has-error {
        border-color: #DB6969;
        border-width: 2px; }
      .login-bg .form-sign .group-input small {
        font-size: 12px;
        text-align: left; }
      .login-bg .form-sign .group-input small.has-error {
        color: #DB6969; }
    .login-bg .form-sign .action-login {
      display: flex;
      justify-content: space-between; }
    .login-bg .form-sign .custom-control-label {
      font-size: 15px !important;
      color: #585858;
      line-height: 25px;
      font-weight: 400; }
  .login-bg .recuperar-senha a {
    color: #7229e6;
    border-bottom: 1px solid #C099FF;
    transition: all .3s linear;
    font-weight: 600; }
    .login-bg .recuperar-senha a:hover {
      text-decoration: none;
      color: #C099FF;
      transition: all .3s linear; }

.login-2 .box-form {
  border-bottom: none;
  margin-top: 40px;
  max-width: 640px; }

.login-2 .fa-lock {
  margin-right: 5px; }

.login-2 h2,
.login-2 h3 {
  font-weight: 600; }

.login-2 h2 {
  font-size: 24px;
  color: #C099FF; }

.login-2 h3 {
  font-size: 18px;
  color: #C099FF; }

.login-2 .logo-login {
  margin-bottom: 50px; }

.login-2 p {
  font-size: 14px;
  color: #888585; }

.login-2 .qr-code {
  border: 1px solid #888585;
  width: 225px;
  max-width: 100%;
  z-index: 9999; }

.login-2 .login-instructions {
  background: #F4F4F4;
  padding: 20px;
  margin-bottom: 10px;
  font-size: 14px; }
  .login-2 .login-instructions .download {
    border-left: 2px solid #fff;
    text-align: center !important; }
    .login-2 .login-instructions .download a {
      color: #7229e6 !important;
      line-height: 110px;
      text-decoration: underline;
      font-weight: 600; }
  .login-2 .login-instructions h3 {
    font-size: 14px;
    color: #7229e6;
    font-weight: 600; }
  .login-2 .login-instructions p span {
    color: #7229e6;
    font-weight: 600; }
  .login-2 .login-instructions p a {
    color: #7229e6;
    text-decoration: underline; }

.login-2 .form-sign {
  margin-top: 0; }

.login-2 label {
  display: inline; }

.text-spacing {
  letter-spacing: 1px; }

@media (max-width: 769px) {
  p {
    text-align: center; }
  h3,
  h2 {
    text-align: center;
    padding-top: 10px; }
  img {
    margin: auto;
    display: block; }
  .login-instructions {
    text-align: center; }
    .login-instructions .download {
      border-left: none !important;
      padding-bottom: 20px; }
      .login-instructions .download a {
        line-height: 10px !important; }
  .btn {
    margin-top: 0px !important; } }

.not-found {
  display: block;
  margin-top: 120px; }
  .not-found h1 {
    text-align: center;
    color: #8AC1E7;
    font-size: 96px;
    font-weight: 900;
    letter-spacing: 20px;
    font-family: 'Open Sans'; }
  .not-found h2 {
    color: #C099FF;
    font-size: 17px;
    font-weight: 500; }
  .not-found p {
    margin-top: 70px;
    color: #C099FF;
    font-size: 14px; }
  .not-found a {
    color: #C099FF; }
  .not-found figure {
    height: 246px;
    width: 175px;
    margin: auto; }

.section-search .btn-active-filter {
  display: none; }

.filter .lista-select-filter {
  display: flex;
  flex-direction: row;
  align-items: center; }
  .filter .lista-select-filter li {
    align-items: left;
    display: flex;
    flex-direction: column;
    margin: 10px; }
    .filter .lista-select-filter li label, .filter .lista-select-filter li span {
      color: #888585;
      font-size: 13px; }
    .filter .lista-select-filter li select {
      height: 35px;
      min-width: 250px; }

.filter .input-group label, .filter .input-group span {
  font-size: 12px;
  color: #888585;
  font-weight: 600; }

.select-process {
  z-index: 3; }

.buttons-validate {
  position: 'sticky';
  top: '0px';
  z-index: 2; }
  .buttons-validate .validador-lista-actions {
    display: flex;
    align-items: center;
    align-content: space-between;
    margin-bottom: 10px; }
    .buttons-validate .validador-lista-actions label, .buttons-validate .validador-lista-actions span {
      display: block;
      color: #888585;
      font-weight: 600;
      font-size: 14px;
      min-width: 120px;
      margin-left: 5px;
      text-align: left;
      margin-bottom: .5rem; }
    .buttons-validate .validador-lista-actions .btn-selected {
      color: #fff;
      background: #9AC75C !important;
      font-size: 11px !important;
      border: none !important;
      padding: 0px 25px !important;
      min-height: 38px; }
    .buttons-validate .validador-lista-actions .btn {
      margin: 6px;
      min-width: 150px;
      min-height: 40px; }

.image-section .buttons-validate {
  position: sticky;
  top: 0px; }

.image-section .validador-lista-actions {
  background: #f1f1f1;
  border: 1px solid #ccc;
  padding-top: 10px;
  padding-bottom: 10px; }

.validador-images {
  margin-top: 20px; }
  .validador-images .lista-img-validar {
    padding: 5px;
    background: white;
    margin-bottom: 30px;
    background: #f2f2f2;
    border: 1px solid #d3d3d3; }
    .validador-images .lista-img-validar figure img {
      position: relative;
      top: 2px; }
    .validador-images .lista-img-validar figure form {
      position: absolute;
      top: 10px;
      left: 35px; }
      .validador-images .lista-img-validar figure form:hover {
        cursor: pointer; }
    .validador-images .lista-img-validar figcaption {
      margin-bottom: 12px; }
      .validador-images .lista-img-validar figcaption article {
        font-size: 11px;
        color: #888585; }
        .validador-images .lista-img-validar figcaption article .validador-txt-destaque {
          color: #7229e6;
          text-transform: uppercase;
          font-weight: 600;
          font-size: 13px; }
        .validador-images .lista-img-validar figcaption article .validador-txt-destaque-silver {
          color: #888585;
          text-transform: uppercase;
          font-weight: 600;
          font-size: 12px; }
    .validador-images .lista-img-validar:focus {
      outline: none; }
    .validador-images .lista-img-validar:hover {
      cursor: pointer; }
  .validador-images .active {
    background: #7229e6; }
    .validador-images .active figcaption article {
      color: white !important; }
      .validador-images .active figcaption article .validador-txt-destaque-silver, .validador-images .active figcaption article .validador-txt-destaque {
        color: white; }

@media (max-width: 768px) {
  .section-search .filter.hidden {
    max-height: 0px;
    overflow: hidden;
    margin-bottom: 20px; }
  .section-search .filter.show {
    max-height: 100%;
    margin-bottom: 20px;
    -webkit-transition: all 1s linear; }
    .section-search .filter.show form {
      background: #f2f2f2; }
  .section-search .btn-active-filter {
    display: block;
    background: #f2f2f2;
    font-size: 14px !important;
    letter-spacing: 4px;
    text-transform: uppercase;
    height: 40px; }
    .section-search .btn-active-filter:hover {
      cursor: pointer; }
    .section-search .btn-active-filter:focus {
      outline: none !important;
      box-shadow: none !important;
      background: #C099FF;
      transition: all .3s ease;
      color: white; }
  .section-search .lista-select-filter {
    margin-top: 20px;
    padding-bottom: 30px;
    margin-bottom: 0px;
    border-bottom: 1px solid #888585; }
  .validador-lista-actions, .lista-select-filter, form {
    width: 100%;
    display: grid !important; }
    .validador-lista-actions select, .lista-select-filter select, form select {
      width: 100% !important; }
    .validador-lista-actions .btn, .lista-select-filter .btn, form .btn {
      width: 100%;
      margin-left: 0px !important;
      margin-right: 0px !important; } }

.history-user {
  margin-bottom: 0px;
  margin-left: 10px;
  font-size: 13px; }

.titulo-tabela-batedor {
  color: #C099FF;
  font-size: 23px; }

.table-situation-title {
  border-bottom: solid #7229e6 1px;
  width: 100%;
  padding-bottom: 10px; }

.table-situation-body {
  padding-top: 15px; }

.field-filter {
  margin-top: 15px;
  padding-bottom: 15px;
  color: #9FA1A2;
  border-bottom: solid #f2f2f2 1px; }

.btn-border {
  margin-left: 15px; }

.input-color::placeholder {
  color: #9FA1A2 !important; }

.input-color {
  color: #9FA1A2 !important; }

.printer-button {
  margin-top: 45px; }

.batedor-table .-headerGroups .rt-th {
  font-size: 12px !important;
  color: #7229e6 !important;
  font-weight: 600;
  padding: 10px;
  height: 100%; }

.batedor-table .-header .rt-tr {
  border-left: #D9D9D9 1px solid;
  border-right: #D9D9D9 1px solid; }
  .batedor-table .-header .rt-tr .rt-resizable-header-content {
    padding-left: auto !important;
    margin: 0px;
    font-size: 12px !important;
    color: #7229e6 !important;
    font-weight: 600; }

.rt-tbody {
  color: #9FA1A2; }
  .rt-tbody .rt-td {
    border-left: #D9D9D9 2px solid;
    height: 100%; }
    .rt-tbody .rt-td:nth-child(1) {
      color: #7229e6 !important; }
  .rt-tbody .rt-tr {
    height: 60px !important; }
    .rt-tbody .rt-tr:nth-child(n) {
      border-right: #D9D9D9 2px solid; }

.btn-eye {
  font-size: 11px !important;
  border: none !important;
  padding: 0px 10px !important;
  background: #7229e6;
  color: #fff;
  min-height: 38px; }

.check-circle-color {
  color: #659C56; }

.times-circle-color {
  color: #DB6969; }

.table-situacao .rt-thead {
  background: #F4F4F4;
  color: #7229e6 !important;
  border-bottom: 2px solid #fff !important; }
  .table-situacao .rt-thead .rt-th {
    border-right: 2px solid #fff !important;
    font-size: 14px;
    padding: 15px 0px !important; }
    .table-situacao .rt-thead .rt-th .rt-resizable-header-content {
      color: #7229e6 !important;
      font-weight: 600 !important; }

.table-situacao .rt-tbody .rt-tr-group {
  background: #F4F4F4;
  border-bottom: 0px !important; }
  .table-situacao .rt-tbody .rt-tr-group:nth-child(even) {
    background: #E9EEF0; }

.table-situacao .rt-tbody .rt-td {
  font-weight: 400 !important; }
  .table-situacao .rt-tbody .rt-td:nth-child(1n) {
    border-right: 2px solid #fff !important; }

.table-situacao .rt-tfoot {
  color: #9FA1A2; }
  .table-situacao .rt-tfoot .rt-td:nth-child(1n) {
    border-right: 2px solid #fff !important; }

@media screen and (max-width: 769px) {
  .printer-button {
    visibility: hidden; } }

#total {
  margin-top: 200px; }

.total p {
  font-size: 14px;
  text-align: center;
  color: #4A4747; }
  .total p span {
    font-weight: 600; }

.tooltip-table {
  font-size: 12px !important;
  color: #7229e6; }

.alerta {
  color: #DD7C60; }

.legendas li {
  color: #4A4747;
  display: inline;
  padding: 0 10px; }
  .legendas li:nth-child(1) {
    font-weight: 600; }
  .legendas li:nth-child(n+2) {
    font-weight: 100; }

.legendas .fas {
  margin-right: 5px; }

.legendas .rosa {
  color: #F0D9D2; }

.legendas .amarelho {
  color: #F6EEC3; }

.table-acompanhamento-lote {
  text-align: center;
  background: #F4F4F4; }
  .table-acompanhamento-lote p {
    margin-bottom: 0 !important;
    padding: 0 !important; }
  .table-acompanhamento-lote .rt-thead {
    background: #F4F4F4 !important;
    border-bottom: 2px solid #fff !important; }
  .table-acompanhamento-lote .-headerGroups .rt-th {
    font-size: 15px !important;
    color: #C099FF !important;
    font-weight: 600;
    text-transform: uppercase;
    border-right: 2px solid #fff !important;
    padding: 10px; }
  .table-acompanhamento-lote .-header {
    color: #C099FF !important;
    font-size: 15px !important;
    box-shadow: none !important;
    -webkit-box-shadow: none !important; }
    .table-acompanhamento-lote .-header .rt-th {
      padding: 15px 0; }
      .table-acompanhamento-lote .-header .rt-th:nth-child(6) {
        border-right: 2px solid #fff !important; }
      .table-acompanhamento-lote .-header .rt-th:nth-child(11) {
        border-right: 2px solid #fff !important; }
      .table-acompanhamento-lote .-header .rt-th:nth-child(15) {
        border-right: 2px solid #fff !important; }
      .table-acompanhamento-lote .-header .rt-th:nth-child(21) {
        border-right: 2px solid #fff !important; }
      .table-acompanhamento-lote .-header .rt-th:nth-child(27) {
        border-right: 2px solid #fff !important; }
    .table-acompanhamento-lote .-header .rt-th .rt-resizable-header-content {
      color: #C099FF !important;
      font-weight: 400 !important; }
    .table-acompanhamento-lote .-header .rt-th:nth-child(n+4) {
      font-size: 15px; }
      .table-acompanhamento-lote .-header .rt-th:nth-child(n+4) .rt-resizable-header-content {
        margin: auto;
        font-weight: 600;
        padding: 2px; }
      .table-acompanhamento-lote .-header .rt-th:nth-child(n+4):nth-child(28) .rt-resizable-header-content {
        min-width: 80% !important; }
  .table-acompanhamento-lote .rt-tr-group .F6EEC3:hover {
    background: #efe3a6 !important; }
  .table-acompanhamento-lote .rt-tr-group .F0D9D2:hover {
    background: #f5cabd !important; }
  .table-acompanhamento-lote .rt-tr-group .rt-td {
    font-size: 13px !important;
    padding: 10px 0 !important;
    color: #484646 !important;
    border-right: 1px solid rgba(0, 0, 0, 0.05) !important;
    width: 100%; }
    .table-acompanhamento-lote .rt-tr-group .rt-td:nth-child(1) {
      border-right: 2px solid #fff;
      border-bottom: none !important;
      border-top: none !important; }
    .table-acompanhamento-lote .rt-tr-group .rt-td:nth-child(6) {
      border-right: 2px solid #fff !important; }
    .table-acompanhamento-lote .rt-tr-group .rt-td:nth-child(11) {
      border-right: 2px solid #fff !important; }
    .table-acompanhamento-lote .rt-tr-group .rt-td:nth-child(15) {
      border-right: 2px solid #fff !important; }
    .table-acompanhamento-lote .rt-tr-group .rt-td:nth-child(21) {
      border-right: 2px solid #fff !important; }
    .table-acompanhamento-lote .rt-tr-group .rt-td:nth-child(27) {
      border-right: 2px solid #fff !important; }
  .table-acompanhamento-lote .rt-tfoot .rt-td {
    background: #7229e6 !important;
    color: #fff !important;
    padding: 0px 0px 0px 0px; }
    .table-acompanhamento-lote .rt-tfoot .rt-td p {
      padding: 10px 0px 10px 0px; }
    .table-acompanhamento-lote .rt-tfoot .rt-td table {
      margin: 0;
      padding: 0;
      max-width: 100%;
      display: block; }
      .table-acompanhamento-lote .rt-tfoot .rt-td table tbody {
        max-width: 100%;
        display: block; }
      .table-acompanhamento-lote .rt-tfoot .rt-td table td {
        max-width: 100%;
        display: block; }
      .table-acompanhamento-lote .rt-tfoot .rt-td table tr {
        padding: 10px 0px 10px 0px;
        display: block; }
        .table-acompanhamento-lote .rt-tfoot .rt-td table tr:nth-child(2) {
          background: #033F7B; }
    .table-acompanhamento-lote .rt-tfoot .rt-td:nth-child(6) {
      border-right: 2px solid #fff !important; }
    .table-acompanhamento-lote .rt-tfoot .rt-td:nth-child(11) {
      border-right: 2px solid #fff !important; }
    .table-acompanhamento-lote .rt-tfoot .rt-td:nth-child(15) {
      border-right: 2px solid #fff !important; }
    .table-acompanhamento-lote .rt-tfoot .rt-td:nth-child(21) {
      border-right: 2px solid #fff !important; }
    .table-acompanhamento-lote .rt-tfoot .rt-td:nth-child(27) {
      border-right: 2px solid #fff !important; }
  .table-acompanhamento-lote .alerta-box {
    font-size: 12px;
    background: #DD7C60;
    color: #fff;
    border-radius: 4px;
    max-width: 40px;
    margin: auto; }
  .table-acompanhamento-lote .reciclagem {
    background: #F6EEC3 !important; }

.button-gerar-tipo {
  color: #fff;
  background-color: #218EC6;
  padding: 5px;
  border-radius: 3px; }

.button-atualizar-banco {
  color: #fff;
  background-color: #5cb85c;
  padding: 5px;
  border-radius: 3px; }

.button-refresh {
  color: #003d7d;
  background-color: #f4f4f4;
  padding: 5px 8px;
  border-radius: 3px;
  float: right; }

.progress {
  background-color: red;
  height: 20px;
  border: 1px solid #333;
  border-radius: 50px; }
